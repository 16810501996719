/**
 * @license
 * MyFonts Webfont Build ID 4177247, 2021-09-07T07:55:46-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Axiforma-Bold by Kastelov
 * URL: https://www.myfonts.com/fonts/kastelov/axiforma/bold/
 *
 * Webfont: Axiforma-Regular by Kastelov
 * URL: https://www.myfonts.com/fonts/kastelov/axiforma/regular/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 2017 by Kastelov. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/* doing it in index.html */

@font-face {
  font-family: "Axiforma-Regular";
  src: url("webFonts/AxiformaRegular/font.woff2") format("woff2"), url("webFonts/AxiformaRegular/font.woff") format("woff");
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url("webFonts/AxiformaBold/font.woff2") format("woff2"), url("webFonts/AxiformaBold/font.woff") format("woff");
}
