@import url("fonts/MyFontsWebfontsKit.css");
@import url("fonts/LatoFonts.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--deep-ocean-color);
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
}

:root {
  --white-color: #ffffff;
  --raised-white-color: #FFFFFF;
  --black-color: #000000;
  --black-light-color: #222626;
  --mint-color: #e4f3e8;
  --mint-50-color: #f1f9f3;
  --mint-30-color: #f7fbf8;
  --mint-110-color: #D4EEDB;
  --mint-120-color: #c8dccd;
  --mint-130-color: #B4C9B9;
  --mint-110b-color: #D4F3DB;
  --mint-120b-color: #BFEAC9;
  --mint-130b-color: #A9DAB3;
  --mint-140b-color: #8AC495;
  --bauhub-green-color: #00b95f;
  --bauhub-green-120-color: #00a455;
  --dark-jungle-color: #0a6343;
  --dark-jungle-110-color: #0B4933;
  --dark-jungle-color-origin: #0a6343;
  --deep-ocean-color: #113132;
  --deep-ocean-color-origin: #113132;
  --deep-ocean-80-color: #415a5b;
  --deep-ocean-60-color: #708384;
  --deep-ocean-40-color: #9ba8a9;
  --pigeon-color: #56747e;
  --pigeon-70-color: #7596a1;
  --pigeon-80-color: #789098;
  --pigeon-60-color: #94adb5;
  --pigeon-55-color: #9DB7C0;
  --pigeon-50-color: #bacad0;
  --pigeon-45-color: #cad7db;
  --pigeon-40-color: #d6dfe3;
  --pigeon-30-color: #DEE5E8;
  --pigeon-20-color: #E6ECEE;
  --smoke-color: #f2f5f6;
  --smoke-50-color: #f8fafa;
  --success-green-color: #6db000;
  --success-green-color-original: #6db000;
  --success-green-110-color: #5e9800;
  --success-green-30-color: #B8D38A;
  --success-green-20-color: #d7edb4;
  --success-green-10-color: #f2f9e6;
  --success-green-5-color: #f8fcf2;
  --error-red-color: #B93D3D;
  --error-red-120-color: #b50000;
  --error-red-30-color: #eab4b4;
  --error-red-20-color: #f7cccc;
  --error-red-10-color: #ffeeee;
  --warning-yellow-color: #edab00;
  --warning-yellow-20-color: #fbeabb;
  --warning-yellow-10-color: #fdf7e6;
  --information-blue-color: #0050e1;
  --royal-color: #7359f7;
  --terra-cotta-color: #bb570e;
  --royal-blue-color: #0A3776;
  --royal-blue-10-color: #E4EBF4;
  --royal-blue-20-color: #c0ccdd;
  --company-name-bg-color: #516394;

  --warning-yellow-110-color: #dc9e00;
  --warning-yellow-120-color: #c89000;

  --crimson-10: #f6ecea;
  --crimson: #bb2612;
  --crimson-20: #e4c8c4;
  --lush-10: #e6f3e0;
  --lush: #24700a;
  --lush-20: #bed1b8;
  --mediterranian-10: #e4eef6;
  --mediterranian: #1a65a7;
  --mediterranian-20: #b9c9d8;
  --navy-10: #ccd8e2;
  --navy: #0c3a62;
  --navy-20: #a6b7c7;
  --eggplant-10: #e0ddf1;
  --eggplant: #320c62;
  --eggplant-20: #c5b7d7;
  --dull-grey-10: #ececeb;
  --dull-grey: #575756;
  --dull-grey-20: #cdcdcd;
  --rhino-10: #eceef0;
  --rhino: #7e8594;
  --rhino-20: #ced3dd;

  --lavendel: #8160B9;
  --electric: #4457FF;
  --teal: #3AA1A8;
  --lime: #8AA100;

  /*  Dark mode colors */
  --charcoal: #1A1D1D;
  --charcoal-95: #282A2A;
  --charcoal-90: #373A3A;
  --charcoal-85: #464C4C;
  --charcoal-80: #4D5656;
  --charcoal-70: #636C6C;
  --charcoal-60: #788282;
  --charcoal-50: #8D9999;
  --charcoal-40: #A1AFAF;
  --charcoal-30: #B6BFBF;
  --charcoal-20: #CED7D7;
  --charcoal-10: #E5EBEB;

  --moss: #2C3B34;
  --moss-130: #85A195;
  --moss-120: #5B7066;
  --moss-110: #405249;
  --moss-50: #232C28;
  --moss-30: #1F2624;

  --success-green-110-dark: #A9E04E;
  --success-green-10-dark: #454E38;
  --success-green-5-dark: #3C4430;

  --error-red-110: #DD4E45;
  --error-red-dark: #FF6B6B;
  --error-red-30-dark: #2D2121;
  --error-red-20-dark: #382424;
  --error-red-10-dark: #482A2A;
  --xeokit-pink: #EA3DEF;

  --warning-yellow-20-dark: #624F1E;
  --warning-yellow-10-dark: #4A3C17;
  --information-blue-dark: #0050E1;
  --rhino-10-dark: #43474F;
  --crimson-dark: #FF6F5B;
  --crimson-20-dark: #6C4E49;
  --crimson-10-dark: #483330;
  --eggplant-20-dark: #605174;
  --eggplant-10-dark: #38274C;
  --mediterranian-20-dark: #436079;
  --mediterranian-10-dark: #193853;
  --lush-dark: #8DC879;
  --lush-20-dark: #55674F;
  --lush-10-dark: #364430;
  --royal-blue-dark: #A1C1EE;
  --royal-blue-20-dark: #4B5E79;
  --royal-blue-10-dark: #2B3C52;
  --navy-dark: #A0D5FF;
  --navy-20-dark: #688299;
  --navy-10-dark: #4F5F6B;
  --dull-grey-20-dark: #828282;
  --3d-background: #f2f2fe;
}

*[data-theme='DARK'] {
  --white-color: var(--charcoal);
  --raised-white-color: var(--charcoal-95);
  --black-color: #FFFFFF;
  --black-light-color: var(--smoke-50-color);
  --mint-color: var(--moss);
  --mint-50-color: var(--moss-50);
  --mint-30-color: var(--moss-30);
  --mint-110-color: var(--moss-110);
  --mint-120-color: var(--moss-120);
  --mint-130-color: var(--moss-130);
  --dark-jungle-color: var(--bauhub-green-color);
  --deep-ocean-color: var(--charcoal-10);
  --deep-ocean-80-color: var(--charcoal-30);
  --deep-ocean-60-color: var(--charcoal-40);
  --deep-ocean-40-color: var(--charcoal-50);
  --pigeon-color: var(--charcoal-30);
  --pigeon-70-color: var(--charcoal-40);
  --pigeon-60-color: var(--charcoal-50);
  --pigeon-50-color: var(--charcoal-60);
  --pigeon-45-color: var(--charcoal-70);
  --pigeon-40-color: var(--charcoal-80);
  --pigeon-30-color: var(--charcoal-80);
  --pigeon-20-color: var(--charcoal-85);
  --smoke-color: var(--charcoal-90);
  --smoke-50-color: var(--charcoal-95);
  --success-green-color: var(--success-green-110-dark);
  --success-green-110-color: var(--success-green-110-dark);
  --success-green-30-color: var(--success-green-5-dark);
  --success-green-20-color: var(--success-green-10-dark);
  --success-green-10-color: var(--success-green-10-dark);
  --success-green-5-color: var(--success-green-10-dark);
  --error-red-color: var(--error-red-dark);
  --error-red-30-color: var(--error-red-30-dark);
  --error-red-20-color: var(--error-red-20-dark);
  --error-red-10-color: var(--error-red-10-dark);
  --warning-yellow-20-color: var(--warning-yellow-20-dark);
  --warning-yellow-10-color: var(--warning-yellow-10-dark);
  --information-blue-color: var(--information-blue-dark);
  --royal-blue-color: var(--royal-blue-dark);
  --royal-blue-20-color: var(--royal-blue-20-dark);
  --royal-blue-10-color: var(--royal-blue-10-dark);

  --crimson-10: var(--crimson-10-dark);
  --crimson: var(--crimson-dark);
  --crimson-20: var(--crimson-20-dark);
  --lush-10: var(--lush-10-dark);
  --lush: var(--lush-dark);
  --lush-20: var(--lush-20-dark);
  --mediterranian-10: var(--mediterranian-10-dark);
  --mediterranian: var(--mediterranian-20);
  --mediterranian-20: var(--mediterranian-20-dark);
  --navy-10: var(--navy-10-dark);
  --navy: var(--navy-dark);
  --navy-20: var(--navy-20-dark);
  --eggplant-10: var(--eggplant-10-dark);
  --eggplant: var(--eggplant-20);
  --eggplant-20: var(--eggplant-20-dark);
  --dull-grey-20: var(--dull-grey-20-dark);
  --rhino-10: var(--rhino-10-dark);
}

html[data-theme='DARK'] .bh-shadow {
  box-shadow: none;
  outline: 1px solid var(--charcoal-85);
}

/* BhPrimaryButton as BhSecondaryButton in Light theme */
html[data-theme='DARK'] .bh-primary-button.bh-bg-dark-jungle {
  /*background-color: var(--mint-color);*/
  background-color: #B4C9B9;
}

html[data-theme='DARK'] .bh-primary-button.bh-text-white {
  /*background-color: var(--deep-ocean-color);*/
  color: #113132;
}

html[data-theme='DARK'] .bh-primary-button.hover\:bh-border-deep-ocean:hover {
  /*background-color: var(--mint-120-color);*/
  border-color: #c8dccd;
}

html[data-theme='DARK'] .bh-primary-button.hover\:bh-bg-deep-ocean:hover {
  /*background-color: var(--mint-120-color);*/
  background-color: #c8dccd;
}

html[data-theme='DARK'] .bh-primary-button.active\:bh-bg-deep-ocean-80:active {
  /*background-color: var(--mint-130-color);*/
  background-color: #B4C9B9;
}

html[data-theme='DARK'] .bh-primary-button.disabled\:bh-bg-pigeon-20:disabled {
  /*background-color: var(--pigeon-20-color);*/
  background-color: #464C4C;
  color: #8D9999;
}

html[data-theme='DARK'] .bh-secondary-button.hover\:bh-bg-mint-120:hover {
  /*background-color: var(--moss-110);*/
  background-color: #405249;
}

/* BhPrimaryButton as BhSecondaryButton in Light theme */

html[data-theme='DARK'] .bh-bg-dark-jungle {
  background-color: var(--dark-jungle-color-origin);
}

html[data-theme='DARK'] .bh-bg-success-green {
  background-color: var(--success-green-color-original);
}

html[data-theme='DARK'] .bh-border-success-green {
  border-color: var(--success-green-color-original);
}

html[data-theme='DARK'] .bh-bg-success-green-110 {
  background-color: var(--success-green-color-original);
}

html[data-theme='DARK'] .bh-border-success-green-110 {
  border-color: var(--success-green-color-original);
}

html[data-theme='DARK'] .hover\:bh-bg-success-green-110:hover {
  background-color: var(--success-green-color-original);
}

html[data-theme='DARK'] .bh-bg-error-red {
  background-color: var(--error-red-110);
}

html[data-theme='DARK'] .bh-bg-deep-ocean, html[data-theme='DARK'] .hover\:bh-bg-deep-ocean:hover {
  background-color: var(--charcoal-95);
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.h-minus-nav {
  height: calc(100vh - 3.5rem);
}

.max-h-minus-200px {
  max-height: calc(100vh - 200px);
}

[type="checkbox"],
[type="radio"] {
  background-color: var(--white-color);
  color: var(--bauhub-green-120-color);
}

[type="checkbox"]:disabled,
[type="radio"]:disabled {
  color: var(--pigeon-60-color);
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
input,
textarea,
select {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--white-color);
  border-color: var(--pigeon-40-color);
  font-size: 14px;
  line-height: 20px;
  border-radius: 0.25rem;
  padding-top: 7px;
  padding-bottom: 7px;
}

[type="number"] {
  padding: 7px 4px;
  height: 1em;
  -moz-appearance: textfield;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[type="checkbox"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 0;
  outline-offset: 0;
  --tw-ring-inset: none;
  --tw-ring-offset-shadow: none;
  --tw-ring-shadow: none;
  box-shadow: none;
  border-color: var(--pigeon-70-color);
}

.tox-tinymce {
  border: 1px solid var(--pigeon-40-color) !important;
  border-radius: 0.25rem !important;
}

*:focus-visible {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--deep-ocean-color);
  font-weight: bold;
}

h1 {
  font-size: 28px;
  line-height: 30px;
  margin-top: 16px;
  margin-bottom: 12px;
}

h2 {
  font-size: 20px;
  line-height: 28px;
  margin-top: 12px;
  margin-bottom: 8px;
}

h3 {
  font-size: 16px;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
}

p {
  margin-top: 6px;
  margin-bottom: 6px;
}

.text-sm {
  font-size: 12px;
  line-height: 16px;
}

.text-lg {
  font-size: 18px !important;
  line-height: 24px;
}

.button-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
}

.badge-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: 0;
}

::-webkit-scrollbar-thumb {
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: var(--pigeon-50-color);
}

.ring-white {
  --tw-ring-color: var(--white-color);
}

*,
::before,
::after {
  border-color: var(--pigeon-40-color);
}

.h-8\.5 {
  height: 2.125rem;
}

.h-11 {
  height: 2.75rem;
}

.h-22 {
  height: 5.5rem;
}

.w-11 {
  width: 2.75rem;
}

.h-13 {
  height: 3.25rem;
}

.h-17 {
  height: 4.25rem;
}

.h-18 {
  height: 4.5rem;
}

.h-30 {
  height: 7.5rem;
}

.h-56px {
  height: 56px;
}

.h-150 {
  height: 600px;
}

.w-15 {
  width: 4rem;
}

.w-18 {
  width: 4.5rem;
}

.w-21 {
  width: 6rem;
}

.w-66 {
  width: 16.5rem;
}

.w-72 {
  width: 18rem;
}

.w-90 {
  width: 22.5rem;
}

.w-34 {
  width: 8.5rem;
}

.w-80-important {
  width: 19rem !important;
}

.focus\:w-full-important:focus {
  width: 100% !important;
}

.min-w-8 {
  min-width: 2rem;
}

.min-w-10 {
  min-width: 2.5rem;
}

.max-w-10 {
  max-width: 2.5rem;
}

.min-w-11 {
  min-width: 2.75rem;
}

.min-w-12 {
  min-width: 3rem !important;
}

.max-w-12 {
  max-width: 3rem !important;
}

.min-w-14 {
  min-width: 3.5rem !important;
}

.max-w-14 {
  max-width: 3.5rem !important;
}

.min-w-16 {
  min-width: 4rem !important;
}

.max-w-16 {
  max-width: 4rem !important;
}

.max-w-80px {
  max-width: 100px;
}


.min-w-20 {
  min-width: 5rem !important;
}

.max-w-20 {
  max-width: 5rem !important;
}

.min-w-24 {
  min-width: 6rem !important;
}

.max-w-24 {
  max-width: 6rem !important;
}

.min-w-26 {
  min-width: 6.5rem !important;
}

.max-w-26 {
  max-width: 6.5rem !important;
}

.min-w-34 {
  min-width: 8.5rem;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-80 {
  min-width: 20rem;
}

.w-74 {
  width: 18.875rem;
}

.w-128 {
  width: 32rem;
}

.bh-table-row td {
  padding: 0 0.75rem;
}

.pr-2 td {
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.pr-1px {
  padding-right: 1px;
}

.h-font-bold {
  font-family: "Axiforma-Bold", sans-serif;
}

.h-font {
  font-family: "Axiforma-Regular", sans-serif;
}

.mx-1\.25 {
  margin-left: 5px;
  margin-right: 5px;
}

.pl-15 {
  padding-left: 3.75rem;
}

.bh-table-header-text {
  font-size: 13px;
  line-height: 16px;
}

.bh-underline-link {
  text-decoration: underline;
}

.bh-underline-link:hover {
  color: var(--dark-jungle-color);
}

/*!* Icons 24px *!*/
/*.svg-inline--fa {*/
/*  padding: 2px;*/
/*  width: 24px;*/
/*  height: 24px;*/
/*}*/

/*.svg-inline--fa.fa-sm {*/
/*  padding: 0;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*}*/

/*.svg-inline--fa.fa-xs {*/
/*  padding: 1px;*/
/*  width: 16px;*/
/*  height: 16px;*/
/*}*/

.bh-text-white {
  color: var(--white-color);
}

.bh-bg-white {
  background-color: var(--white-color);
}

.bh-border-white {
  border-color: var(--white-color);
}

.bh-border-white-important {
  border-color: var(--white-color) !important;
}

.bh-border-raised-white {
  border-color: var(--raised-white-color);
}

.disabled\:bh-text-white:disabled {
  color: var(--white-color);
}

.disabled\:hover\:bh-bg-white:disabled:hover {
  background-color: var(--white-color);
}

.disabled\:bh-bg-white:disabled {
  background-color: var(--white-color);
}

.disabled\:bh-border-white:disabled {
  border-color: var(--white-color);
}

.hover\:bh-bg-white:hover {
  background-color: var(--white-color) !important;
}

.hover\:bh-border-white:hover {
  border-color: var(--white-color);
}

.hover\:bh-text-white:hover {
  color: var(--white-color);
}

.focus\:bh-bg-white:focus {
  background-color: var(--white-color) !important;
}

.active\:bh-text-white:active {
  color: var(--white-color);
}

.bh-bg-raised-white {
  background-color: var(--raised-white-color);
}

.bh-text-black {
  color: var(--black-color);
}

.bh-bg-black {
  background-color: var(--black-color);
}

.bh-border-black {
  border-color: var(--black-color);
}

.bh-text-mint {
  color: var(--mint-color);
}

.bh-bg-mint {
  background-color: var(--mint-color);
}

.bh-border-mint {
  border-color: var(--mint-color);
}

.hover\:bh-bg-mint:hover {
  background-color: var(--mint-color);
}

.bh-text-mint-50 {
  color: var(--mint-50-color);
}

.bh-bg-mint-50 {
  background-color: var(--mint-50-color);
}

.bh-border-mint-50 {
  border-color: var(--mint-50-color);
}

.hover\:bh-bg-mint-50:hover {
  background-color: var(--mint-50-color);
}

.\!hover\:bh-bg-mint-50:hover {
  background-color: var(--mint-50-color) !important;
}

.bh-text-mint-30 {
  color: var(--mint-30-color);
}

.bh-bg-mint-30 {
  background-color: var(--mint-30-color);
}

.bh-border-mint-30 {
  border-color: var(--mint-30-color);
}

.hover\:bh-bg-mint-30:hover {
  background-color: var(--mint-30-color);
}

.bh-bg-dull-grey-10 {
  background-color: var(--dull-grey-10);
}

.bh-text-dull-grey {
  color: var(--dull-grey);
}

.bh-text-mint-110 {
  color: var(--mint-110-color);
}

.disabled\:bh-text-mint-130:disabled {
  color: var(--mint-130-color);
}

.bh-bg-mint-130b {
  background-color: var(--mint-130b-color);
}

.bh-border-mint-110 {
  border-color: var(--mint-110-color);
}

.hover\:bh-bg-mint-110:hover {
  background-color: var(--mint-110-color);
}

.bh-text-information-blue {
  color: var(--information-blue-color);
}

.bh-text-bauhub-green {
  color: var(--bauhub-green-color);
}

.bh-bg-bauhub-green {
  background-color: var(--bauhub-green-color);
}

.bh-border-bauhub-green {
  border-color: var(--bauhub-green-color);
}

.child-bold-bh-text-bauhub-green strong {
  color: var(--bauhub-green-color);
}

.bh-text-bauhub-green-120 {
  color: var(--bauhub-green-120-color);
}

.hover\:bh-text-bauhub-green-120:hover {
  color: var(--bauhub-green-120-color);
}

.hover\:bh-bg-bauhub-green-120:hover {
  background-color: var(--bauhub-green-120-color);
}

.bh-bg-bauhub-green-120 {
  background-color: var(--bauhub-green-120-color);
}

.bh-border-bauhub-green-120 {
  border-color: var(--bauhub-green-120-color);
}

.bh-text-dark-jungle {
  color: var(--dark-jungle-color);
}

.bh-bg-dark-jungle {
  background-color: var(--dark-jungle-color);
}

.bh-border-dark-jungle {
  border-color: var(--dark-jungle-color);
}

.bh-outline-dark-jungle {
  outline-color: var(--dark-jungle-color);
}

.hover\:bh-text-dark-jungle:hover {
  color: var(--dark-jungle-color);
}

.hover\:bh-border-dark-jungle:hover {
  border-color: var(--dark-jungle-color);
}


.active\:bh-border-dark-jungle:active {
  border-color: var(--dark-jungle-color);
}

.\!hover\:bh-text-dark-jungle:hover {
  color: var(--dark-jungle-color);
}

.bh-bg-dark-jungle-110 {
  background-color: var(--dark-jungle-110-color);
}

.hover\:bh-bg-dark-jungle-110:hover {
  background-color: var(--dark-jungle-110-color);
}

.bh-text-deep-ocean {
  color: var(--deep-ocean-color);
}

.hover\:bh-text-deep-ocean:hover {
  color: var(--deep-ocean-color);
}

.bh-text-deep-ocean-imp {
  color: var(--deep-ocean-color) !important;
}

.bh-bg-deep-ocean {
  background-color: var(--deep-ocean-color);
}

.hover\:bh-bg-deep-ocean:hover {
  background-color: var(--deep-ocean-color);
}

.bh-border-deep-ocean {
  border-color: var(--deep-ocean-color);
}

.hover\:bh-border-deep-ocean:hover {
  border-color: var(--deep-ocean-color);
}

.focus\:bh-border-deep-ocean:focus {
  border-color: var(--deep-ocean-color);
}

.focus-within\:bh-border-deep-ocean:focus-within {
  border-color: var(--deep-ocean-color) !important;
}

.focus\:bh-outline-deep-ocean:focus {
  outline: solid var(--deep-ocean-color);
}

.bh-text-deep-ocean-80 {
  color: var(--deep-ocean-80-color);
}

.hover\:bh-text-deep-ocean-80:hover {
  color: var(--deep-ocean-80-color);
}

.hover\:bh-text-deep-ocean-80-imp:hover {
  color: var(--deep-ocean-80-color) !important;
}

.bh-bg-deep-ocean-80 {
  background-color: var(--deep-ocean-80-color);
}

.bh-border-deep-ocean-80 {
  border-color: var(--deep-ocean-80-color);
}

.hover\:bh-border-deep-ocean-80:hover {
  border-color: var(--deep-ocean-80-color);
}

.divide-y-bh-deep-ocean-80 > tr:not(:last-child) {
  border-bottom: 1px solid var(--deep-ocean-80-color);
}

.divide-y-bh-pigeon-50 > tr:not(:last-child) {
  border-bottom: 1px solid var(--pigeon-50-color);
}

.bh-input-border-deep-ocean-80 input {
  border-color: var(--deep-ocean-80-color);
}

.bh-text-deep-ocean-60 {
  color: var(--deep-ocean-60-color);
}

.bh-bg-deep-ocean-60 {
  background-color: var(--deep-ocean-60-color);
}

.bh-bg-company-name {
  background-color: var(--company-name-bg-color);
}

.bh-border-deep-ocean-60 {
  border-color: var(--deep-ocean-60-color);
}

.hover\:bh-bg-deep-ocean-60:hover {
  background-color: var(--deep-ocean-60-color);
}

.hover\:bh-bg-deep-ocean-80:hover {
  background-color: var(--deep-ocean-80-color);
}

.placeholder\:bh-text-deep-ocean-60::-webkit-input-placeholder,
.placeholder\:bh-text-deep-ocean-60:-ms-input-placeholder,
.placeholder\:bh-text-deep-ocean-60::placeholder {
  color: var(--deep-ocean-60-color);
}

.disabled\:bh-text-deep-ocean-60:disabled {
  color: var(--deep-ocean-60-color);
}

.disabled\:bh-text-deep-ocean:disabled {
  color: var(--deep-ocean-color) !important;
}

.bh-text-deep-ocean-40 {
  color: var(--deep-ocean-40-color);
}

.bh-bg-deep-ocean-40 {
  background-color: var(--deep-ocean-40-color);
}

.bh-border-deep-ocean-40 {
  border-color: var(--deep-ocean-40-color);
}

.placeholder\:bh-text-deep-ocean-40::-webkit-input-placeholder,
.placeholder\:bh-text-deep-ocean-40:-ms-input-placeholder,
.placeholder\:bh-text-deep-ocean-40::placeholder {
  color: var(--deep-ocean-40-color);
}

.disabled\:bh-text-deep-ocean-40:disabled {
  color: var(--deep-ocean-40-color);
}

.bh-bg-xeokit-pink {
  background: var(--xeokit-pink);
}

.bh-caret-xeokit-pink {
  caret-color: var(--xeokit-pink);
}

.bh-text-pigeon {
  color: var(--pigeon-color);
}

.bh-bg-pigeon {
  background-color: var(--pigeon-color);
}

.bh-bg-pigeon-30 {
  background-color: var(--pigeon-30-color);
}

.bh-bg-light-black {
  background-color: var(--black-light-color);
}

.bh-raised-white {
  background-color: var(--raised-white-color);
}

.bh-border-pigeon {
  border-color: var(--pigeon-color);
}

.hover\:bh-border-pigeon:hover {
  border-color: var(--pigeon-color);
}

.hover\:bh-outline-pigeon:hover {
  outline-color: var(--pigeon-color);
}

.hover\:bh-text-pigeon:hover {
  color: var(--pigeon-color);
}

.bh-text-pigeon-70 {
  color: var(--pigeon-70-color);
}

.bh-text-pigeon-80 {
  color: var(--pigeon-80-color);
}

.bh-bg-pigeon-70 {
  background-color: var(--pigeon-70-color);
}

.hover\:bh-bg-pigeon-70:hover {
  background-color: var(--pigeon-70-color);
}

.bh-border-pigeon-70 {
  border-color: var(--pigeon-70-color);
}

.hover\:bh-border-pigeon-70:hover {
  border-color: var(--pigeon-70-color);
}

.focus\:bh-border-pigeon-70:focus {
  border-color: var(--pigeon-70-color) !important;
}

.hover\:bh-outline-pigeon-70:hover:not(:focus) {
  outline: solid var(--pigeon-70-color);
}

.bh-pigeon-70-separator {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--pigeon-70-color);
  margin-top: 16px;
  margin-bottom: 16px;
}

.bh-pigeon-30-separator {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--pigeon-30-color);
  margin-top: 32px;
  margin-bottom: 32px;
}

.bh-text-pigeon-60 {
  color: var(--pigeon-60-color);
}

.bh-bg-pigeon-60 {
  background-color: var(--pigeon-60-color);
}

.bh-input-bg-pigeon-60 input {
  background-color: var(--pigeon-60-color);
}

.bh-border-pigeon-60 {
  border-color: var(--pigeon-60-color);
}

.disabled\:bh-bg-pigeon-60:disabled {
  background-color: var(--pigeon-60-color);
}

.hover\:bh-bg-pigeon-60:hover {
  background-color: var(--pigeon-60-color);
}

.hover\:bh-border-pigeon-60:hover {
  border-color: var(--pigeon-60-color);
}

.disabled\:bh-text-pigeon-60:disabled {
  color: var(--pigeon-60-color);
}

.disabled\:bh-bg-pigeon-20:disabled {
  background-color: var(--pigeon-20-color);
}

.disabled\:bh-text-pigeon-20:disabled {
  color: var(--pigeon-20-color);
}

.bh-text-pigeon-50 {
  color: var(--pigeon-50-color);
}

.bh-bg-pigeon-50 {
  background-color: var(--pigeon-50-color);
}

.\!active\:bh-bg-pigeon-50:active {
  background-color: var(--pigeon-50-color) !important;
}

.bh-input-bg-pigeon-50 input {
  background-color: var(--pigeon-50-color);
}

.bh-border-pigeon-50 {
  border-color: var(--pigeon-50-color);
}

.hover\:bh-border-pigeon-50:hover {
  border-color: var(--pigeon-50-color);
}

.bh-td-border-pigeon-50 td {
  border-color: var(--pigeon-50-color);
}

.bh-text-pigeon-55 {
  color: var(--pigeon-55-color);
}

.bh-pigeon-40-separator {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--pigeon-40-color);
}

.bh-pigeon-40-separator-vertical {
  height: 100%;
  width: 1px;
  border-right: 1px solid var(--pigeon-40-color);
}

.bh-pigeon-20-separator {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--pigeon-20-color);
}

.bh-no-top-separator {
  border-top: 0;
}

.bh-bg-eggplant-10 {
  background-color: var(--eggplant-10);
}

.bh-text-eggplant {
  color: var(--eggplant);
}

.bh-bg-lush-10 {
  background-color: var(--lush-10);
}

.bh-text-lush {
  color: var(--lush);
}

.bh-bg-pigeon-60-alpha95 {
  background-color: #94adb5bb;
}

.disabled\:bh-text-pigeon-50:disabled {
  color: var(--pigeon-50-color);
}

.hover\:bh-bg-pigeon-45:hover {
  background-color: var(--pigeon-45-color);
}

.bh-text-pigeon-40 {
  color: var(--pigeon-40-color);
}

.bh-bg-pigeon-40 {
  background-color: var(--pigeon-40-color);
}

.bh-input-bg-pigeon-40 input {
  background-color: var(--pigeon-40-color);
}

.bh-border-pigeon-40 {
  border-color: var(--pigeon-40-color);
}

.disabled\:bh-border-pigeon-40:disabled {
  border-color: var(--pigeon-40-color);
}

.\!bh-border-pigeon-40 {
  border-color: var(--pigeon-40-color) !important;
}

.bh-td-border-pigeon-40 td {
  border-color: var(--pigeon-40-color);
}

.bh-outline-pigeon-40 {
  outline-color: var(--pigeon-40-color);
}

.hover\:bh-bg-pigeon-40:hover {
  background-color: var(--pigeon-40-color);
}

.bh-text-pigeon-20 {
  color: var(--pigeon-20-color);
}

.bh-bg-pigeon-20 {
  background-color: var(--pigeon-20-color);
}

.bh-border-pigeon-20 {
  border-color: var(--pigeon-20-color);
}

.hover\:bh-bg-pigeon-20:hover {
  background-color: var(--pigeon-20-color);
}

.not-focus\:hover\:bh-bg-pigeon-20:hover:not(:focus) {
  background-color: var(--pigeon-20-color);
}

.hover\:bh-border-pigeon-20:hover {
  border-color: var(--pigeon-20-color);
}

.bh-text-smoke {
  color: var(--smoke-color);
}

.bh-text-smoke-child * {
  color: var(--smoke-color);
}

.bh-bg-smoke {
  background-color: var(--smoke-color);
}

.bh-input-bg-smoke input {
  background-color: var(--smoke-color);
}

.bh-border-smoke {
  border-color: var(--smoke-color);
}

.hover\:bh-bg-smoke:hover {
  background-color: var(--smoke-color);
}

.hover\:bh-border-smoke:hover {
  border-color: var(--smoke-color);
}

.disabled\:bh-bg-smoke:disabled {
  background-color: var(--smoke-color);
}

.disabled\:bh-border-smoke:disabled {
  border-color: var(--smoke-color);
}

.filled\:bh-bg-smoke:not(:placeholder-shown) {
  background-color: var(--smoke-color);
}

.bh-text-smoke-50 {
  color: var(--smoke-50-color);
}

.bh-bg-smoke-50 {
  background-color: var(--smoke-50-color);
}

.bh-border-smoke-50 {
  border-color: var(--smoke-50-color);
}

.hover\:bh-bg-smoke-50:hover {
  background-color: var(--smoke-50-color) !important;
}

.hover\:bh-border-smoke-50:hover {
  border-color: var(--smoke-50-color);
}

.disabled\:bh-bg-smoke-50:disabled {
  background-color: var(--smoke-50-color);
}

.disabled\:bh-border-smoke-50:disabled {
  border-color: var(--smoke-50-color);
}

.filled\:bh-bg-smoke-50:not(:placeholder-shown) {
  background-color: var(--smoke-50-color) !important;
}

.bh-text-rhino {
  color: var(--rhino);
}

.bh-bg-rhino-10 {
  background-color: var(--rhino-10);
}

.hover\:.bh-bg-rhino-20 {
  background-color: var(--rhino-20);
}

.bh-text-success-green {
  color: var(--success-green-color);
}

.bh-bg-success-green {
  background-color: var(--success-green-color);
}

.bh-border-success-green {
  border-color: var(--success-green-color);
}

.bh-text-success-green-110 {
  color: var(--success-green-110-color) !important;
}

.bh-bg-success-green-110 {
  background-color: var(--success-green-110-color);
}

.bh-border-success-green-110 {
  border-color: var(--success-green-110-color);
}

.hover\:bh-bg-success-green-110:hover {
  background-color: var(--success-green-110-color);
}

.active\:bh-bg-success-green-30:active {
  background-color: var(--success-green-30-color) !important;
}

.bh-bg-success-green-30 {
  background-color: var(--success-green-30-color);
}

.bh-text-success-green-20 {
  color: var(--success-green-20-color);
}

.bh-bg-success-green-20 {
  background-color: var(--success-green-20-color);
}

.bh-border-success-green-20 {
  border-color: var(--success-green-20-color);
}

.hover\:bh-bg-success-green-20:hover {
  background-color: var(--success-green-20-color);
}

.bh-text-success-green-5 {
  color: var(--success-green-5-color);
}

.bh-bg-success-green-5 {
  background-color: var(--success-green-5-color);
}

.bh-border-success-green-5 {
  border-color: var(--success-green-5-color);
}

.bh-text-success-green-10 {
  color: var(--success-green-10-color);
}

.bh-bg-success-green-10 {
  background-color: var(--success-green-10-color);
}

.bh-border-success-green-10 {
  border-color: var(--success-green-10-color);
}

.bh-text-error-red {
  color: var(--error-red-color);
}

.bh-bg-error-red {
  background-color: var(--error-red-color);
}

.bh-border-error-red {
  border-color: var(--error-red-color);
}

.active\:bh-border-error-red:active {
  border-color: var(--error-red-color);
}

.bh-text-error-red-20 {
  color: var(--error-red-20-color);
}

.bh-bg-error-red-20 {
  background-color: var(--error-red-20-color);
}

.bh-border-error-red-20 {
  border-color: var(--error-red-20-color);
}

.hover\:bh-bg-error-red-20:hover {
  background-color: var(--error-red-20-color);
}

.disabled\:bh-text-error-red-30:disabled {
  color: var(--error-red-30-color);
}

.bh-text-error-red-30 {
  color: var(--error-red-30-color);
}

.bh-bg-error-red-30 {
  background-color: var(--error-red-30-color);
}

.bh-border-error-red-30 {
  border-color: var(--error-red-30-color);
}

.active\:bh-bg-error-red-30:active {
  background-color: var(--error-red-30-color);
}

.bh-text-error-red-120 {
  color: var(--error-red-120-color);
}

.bh-bg-error-red-120 {
  background-color: var(--error-red-120-color);
}

.bh-border-error-red-120 {
  border-color: var(--error-red-120-color);
}

.bh-text-error-red-10 {
  color: var(--error-red-10-color);
}

.bh-bg-error-red-10 {
  background-color: var(--error-red-10-color);
}

.bh-border-error-red-10 {
  border-color: var(--error-red-10-color);
}

.bh-text-warning-yellow {
  color: var(--warning-yellow-color);
}

.bh-bg-warning-yellow {
  background-color: var(--warning-yellow-color);
}

.bh-border-warning-yellow {
  border-color: var(--warning-yellow-color);
}

.hover\:bh-bg-warning-yellow-110:hover {
  background-color: var(--warning-yellow-110-color);
}

.active\:bh-bg-warning-yellow-120:active {
  background-color: var(--warning-yellow-120-color);
}

.bh-text-warning-yellow-10 {
  color: var(--warning-yellow-10-color);
}

.bh-text-warning-yellow-10-dark {
  color: var(--warning-yellow-10-dark);
}

.bh-text-warning-yellow-120 {
  color: var(--warning-yellow-120-color);
}

.bh-bg-warning-yellow-120 {
  background-color: var(--warning-yellow-120-color);
}

.bh-bg-warning-yellow-10 {
  background-color: var(--warning-yellow-10-color);
}

.bh-bg-warning-yellow-20 {
  background-color: var(--warning-yellow-20-color);
}

.bh-border-warning-yellow-10 {
  border-color: var(--warning-yellow-10-color);
}

.bh-text-royal {
  color: var(--royal-color);
}

.bh-bg-royal {
  background-color: var(--royal-color);
}

.bh-border-royal {
  border-color: var(--royal-color);
}

.bh-text-terra-cotta {
  color: var(--terra-cotta-color);
}

.bh-bg-terra-cotta {
  background-color: var(--terra-cotta-color);
}

.bh-border-terra-cotta {
  border-color: var(--terra-cotta-color);
}

.bh-border-mint-120 {
  border-color: var(--mint-120b-color);
}

.bh-border-mint-130 {
  border-color: var(--mint-130-color);
}

.bh-bg-mint-120b {
  background-color: var(--mint-120b-color);
}

.bh-bg-mint-110b {
  background-color: var(--mint-110b-color);
}

.hover\:bh-bg-mint-120:hover {
  background-color: var(--mint-120-color);
}

.hover\:bh-bg-mint-120b:hover {
  background-color: var(--mint-120b-color);
}

.\!hover\:bh-bg-mint-120b:hover {
  background-color: var(--mint-120b-color) !important;
}

.\!hover\:bh-bg-mint-120:hover {
  background-color: var(--mint-120-color) !important;
}

.bh-bg-mint-110 {
  background-color: var(--mint-110-color);
}

.bh-bg-mint-140b {
  background-color: var(--mint-140b-color);
}

.active\:bh-bg-mint-130b:active {
  background-color: var(--mint-130b-color);
}

.active\:bh-bg-mint-130:active {
  background-color: var(--mint-130-color);
}

.active\:bh-bg-pigeon-20:active {
  background-color: var(--pigeon-20-color);
}

.active\:bh-bg-pigeon-40:active {
  background-color: var(--pigeon-40-color);
}

.active\:bh-bg-deep-ocean-80:active {
  background-color: var(--deep-ocean-80-color);
}

.active\:bh-bg-deep-ocean-60:active {
  background-color: var(--deep-ocean-60-color);
}

.active\:bh-text-deep-ocean:active {
  color: var(--deep-ocean-color);
}

.bh-text-royal-blue {
  color: var(--royal-blue-color);
}

.bh-bg-royal-blue {
  background-color: var(--royal-blue-color);
}

.bh-border-royal-blue {
  border-color: var(--royal-blue-color);
}

.bh-text-royal-blue-light {
  color: var(--royal-blue-10-color);
}

.bh-bg-royal-blue-light {
  background-color: var(--royal-blue-10-color);
}

.bh-border-royal-blue-light {
  border-color: var(--royal-blue-10-color);
}

.bh-bg-3d-background {
  background-color: var(--3d-background);
}

.bh-drag-bg {
  background-image: linear-gradient(var(--smoke-50-color), var(--smoke-color));
}

.text-4px {
  font-size: 4px;
}

.text-9px {
  font-size: 9px;
}

.text-11px {
  font-size: 11px;
}

.text-12px {
  font-size: 12px;
}

.text-13px, .text-13px * {
  font-size: 13px;
}

.text-14px {
  font-size: 14px;
}

.text-16px {
  font-size: 16px;
}

.text-18px {
  font-size: 18px;
}

.text-20px {
  font-size: 20px;
}

.text-24px {
  font-size: 24px;
}

.text-38px {
  font-size: 38px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.l-h-12px {
  line-height: 12px;
}

.l-h-14px, .l-h-14px * {
  line-height: 14px;
}

.l-h-16px {
  line-height: 16px;
}

.l-h-18px {
  line-height: 18px;
}

.l-h-20px {
  line-height: 20px;
}

.l-h-24px {
  line-height: 24px;
}

.l-h-28px {
  line-height: 28px;
}

.max-h-78px {
  max-height: 78px;
}

.min-h-5 {
  min-height: 1.25rem;
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-14 {
  min-height: 3.5rem;
}

.min-h-120 {
  min-height: 30rem;
}

.min-h-180 {
  min-height: 45rem;
}

.min-h-56px {
  min-height: 56px;
}

.min-w-10p {
  min-width: 10%;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-24 {
  min-width: 6rem;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-64 {
  min-width: 16rem;
}

.min-w-25px {
  min-width: 25px;
}

.min-h-48px {
  min-height: 48px;
}

.min-w-70px {
  min-width: 70px;
}

.min-w-50px {
  min-width: 50px;
}

.min-w-92px {
  min-width: 92px;
}

.min-w-132px {
  min-width: 132px;
}

.min-w-196px {
  min-width: 196px;
}

.min-w-320px {
  min-width: 320px;
}

.min-w-864px {
  min-width: 864px;
}

.w-116px {
  width: 116px;
}

.w-320px {
  width: 320px;
}

.w-416px {
  width: 416px;
}

.w-544px {
  width: 544px;
}

.w-640px {
  width: 640px;
}

.w-864px {
  width: 864px;
}

.w-1088px {
  width: 1088px;
}

.w-24px {
  width: 24px !important;
}

.h-24px {
  height: 24px !important;
}

.w-16px {
  width: 16px !important;
}

.h-16px {
  height: 16px !important;
}

.w-11px {
  width: 11px !important;
}

.h-11px {
  height: 11px !important;
}

.max-w-100px {
  max-width: 100px;
}

.max-w-188px {
  max-width: 188px;
}

.max-w-224px {
  max-width: 224px;
}

.max-w-420px {
  max-width: 420px;
}

.max-w-600px {
  max-width: 600px
}

.max-w-480px {
  min-width: 480px;
}

.max-w-600px {
  max-width: 600px
}

.max-w-640px {
  max-width: 640px;
}

.max-w-1152px {
  max-width: 1152px;
}

.max-w-660px {
  max-width: 660px;
}

.min-max-w-xs {
  width: 20rem;
}

.min-max-w-sm {
  width: 24rem;
}

.min-max-w-md {
  width: 28rem;
}

.min-max-w-lg {
  width: 32rem;
}

.min-max-w-xl {
  width: 36rem;
}

.min-max-w-2xl {
  width: 42rem;
}

.min-max-w-3xl {
  width: 48rem;
}

.min-max-w-4xl {
  width: 56rem;
}

.min-max-w-5xl {
  width: 64rem;
}

.min-max-w-6xl {
  width: 72rem;
}

.min-max-w-7xl {
  width: 80rem;
}

button:disabled {
  cursor: not-allowed;
}

a {
  border-radius: 0.25rem;
}

.-rotate-30 {
  transform: rotate(-30deg);
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}

.slice-mouse-icon-move {
  animation-duration: 1200ms; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-delay: 400ms; /* how long to delay the animation from starting */
  animation-iteration-count: 10; /* how many times the animation will play */
  animation-name: slideDown; /* the name of the animation we defined above */
}

.cancel-animation-on-hover:hover * {
  animation-name: none;
}

button:focus-visible,
a:focus-visible {
  outline: 4px solid var(--pigeon-50-color) !important;
}

.bh-border-color-gray {
  border-color: var(--pigeon-50-color);
}

.bh-border-color-dark-green {
  border-color: var(--deep-ocean-color);
}

.hover\:bh-border-color-dark-green:hover {
  border-color: var(--deep-ocean-color);
}

.bh-text-color-dark-green {
  color: #586f70;
}

.bh-text-color-light-gray {
  color: #6b7f80;
}

.py-0\.25 {
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
}

.py-0\.5-imp {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.p-0-imp {
  padding: 0 !important;
}

.px-1\.25 {
  padding-left: 5px;
  padding-right: 5px;
}

.\!px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-7px {
  padding-top: 7px;
  padding-bottom: 7px;
}

input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

input[type="text"], .fake-input {
  min-width: 110px;
}

.three-line-overflow {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 60px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bh-animate-loading-strip {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.bh-shadow {
  box-shadow: 0px 3px 10px rgba(86, 116, 126, 0.3);
}

.bh-shadow-notifications {
  box-shadow: 0px 3px 10px rgba(35, 42, 45, 0.4), 0px 0px 0px 1px #E6ECEE;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.w-fit {
  width: fit-content;
}

.ml-1\.25 {
  margin-left: 5px;
}

.ml-0\.25 {
  margin-left: 1px;
}

.ml-7\.5 {
  margin-left: 30px;
}

.ml-18 {
  margin-left: 4.625rem;
}

.my-0-i {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-1-i {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.m-18px {
  margin: 18px;
}

.mt-18px {
  margin-top: 18px;
}

.my-18px {
  margin-top: 18px;
  margin-bottom: 18px;
}

.not-last\:border-b-0:not(:last-child) {
  border-bottom: 0;
}

.not-last\:border-b-0:not(:last-child):hover {
  border-bottom: solid 1px var(--pigeon-70-color);
  margin-bottom: -1px;
  z-index: 10;
}

.hover\:bg-none {
  background: none !important;
}

.bg-none {
  background: none !important;
}

.bh-hover-container .bh-hover-item {
  display: none;
}

.bh-hover-container .bh-hover-item-opacity {
  opacity: 0;
}

.bh-hover-container:hover .bh-hover-item {
  display: block;
}

.bh-hover-container:hover .bh-hover-item-opacity {
  opacity: 100%;
}

.bh-hover-container:hover .bh-hover-item-hide {
  display: none;
}

.bh-file-card-large-text {
  max-width: 160px;
}

.bh-file-card-large:hover .bh-file-card-large-text {
  max-width: 122px;
}

.bh-file-card-small-icon {
  width: 16px;
  height: 16px;
  font-size: 4px;
  font-weight: 900;
}

.bh-file-card-small-icon svg {
  width: 12px;
  height: 12px;
}

.bh-file-card-medium-icon {
  width: 24px;
  height: 24px;
  font-size: 6px;
  font-weight: 900;
}

.bh-file-card-medium-icon svg {
  width: 16px;
  height: 16px;
}

.bh-file-card-large-icon svg {
  width: 18px;
  height: 18px;
}

.bh-file-card-large-icon {
  width: 32px;
  height: 32px;
  font-size: 10px;
  font-weight: 900;
}

.bh-text-mediterranean {
  color: var(--mediterranian)
}

.bh-bg-mediterranean {
  background-color: var(--mediterranian)
}

.bh-bg-mediterranean-10 {
  background-color: var(--mediterranian-10);
}

.bh-file-card-icon-asice {
  background-color: var(--royal-blue-10-color);
  border: 0.75px solid var(--royal-blue-20-color);
  color: var(--royal-blue-color);
}

.bh-file-card-icon-pdf {
  background-color: var(--crimson-10);
  border: 0.75px solid var(--crimson-20);
  color: var(--crimson);
}

.bh-file-card-icon-xls {
  background-color: var(--lush-10);
  border: 0.75px solid var(--lush-20);
  color: var(--lush);
}

.bh-file-card-icon-doc {
  background-color: var(--mediterranian-10);
  border: 0.75px solid var(--mediterranian-20);
  color: var(--mediterranian);
}

.bh-file-card-icon-dwg {
  background-color: var(--navy-10);
  border: 0.75px solid var(--navy-20);
  color: var(--navy);
}

.bh-file-card-icon-ifc {
  background-color: var(--eggplant-10);
  border: 0.75px solid var(--eggplant-20);
  color: var(--eggplant);
}

.bh-file-card-icon-zip {
  background-color: var(--dull-grey-10);
  border: 0.75px solid var(--dull-grey-20);
  color: var(--dull-grey);
}

.bh-file-card-icon-file {
  background-color: var(--rhino-10);
  border: 0.75px solid var(--rhino-20);
  color: var(--rhino);
}

.bh-file-card-icon-bauhub-entity {
  background-color: var(--mint-color);
  border: 0.75px solid var(--mint-120b-color);
  color: var(--bauhub-green-color);
}

.z-100 {
  z-index: 100;
}

.z-60 {
  z-index: 60;
}

.z-50 {
  z-index: 50;
}

.z-500 {
  z-index: 500;
}

.z-40 {
  z-index: 40;
}

.z-30 {
  z-index: 30;
}

.z-20 {
  z-index: 20;
}

.z-15 {
  z-index: 15;
}

.z-10 {
  z-index: 10;
}

.z-2 {
  z-index: 2;
}

.z-4 {
  z-index: 4;
}

.z-6 {
  z-index: 6;
}

.z-8 {
  z-index: 8;
}

.file-container-name .filename {
  line-height: 3em;
}

.filename {
  display: flex;
  min-width: 0;
}

.filename__base {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.filename__extension {
  display: inline-block;
  flex-shrink: 0;
}

.task-priority-1 {
  background-color: var(--mediterranian);
  color: var(--white-color);
}

.task-priority-2 {
  background-color: var(--royal-blue-20-color);
  color: var(--mediterranian);
}

.task-priority-4 {
  background-color: var(--error-red-20-color);
  color: var(--error-red-120-color);
}

.task-priority-5 {
  background-color: var(--error-red-120-color);
  color: var(--white-color);
}

.task-column-scrollbar::-webkit-scrollbar {
  background: var(--smoke-color);
  width: 2px;
}

.task-column-scrollbar::-webkit-scrollbar-thumb {
  background: var(--pigeon-50-color);
  border-radius: 5px;
}

.taskboard-list-scrollbar::-webkit-scrollbar {
  background: var(--white-color);
  width: 5px;
}

.taskboard-list-scrollbar::-webkit-scrollbar-thumb {
  background: var(--pigeon-70-color);
  border-radius: 5px;
}

.not-first\:border-t-dark-jungle:not(.first\:border-t:first-child) {
  box-shadow: 0 -1px 0 var(--dark-jungle-color);
}

.not-first\:hover\:border-t-pigeon-color:hover:not(.first\:border-t:first-child) {
  box-shadow: 0 -1px 0 var(--pigeon-color);
}

.top-0\.25 {
  top: 1px;
}

.right-0\.25 {
  right: 1px;
}

.w-comment-textarea {
  width: calc(100% - 40px);
}

.max-h-120 {
  max-height: 30rem;
}

.max-h-70-vh {
  max-height: 70vh;
}

.max-h-80-vh {
  max-height: 80vh;
}

.mt-30 {
  margin-top: 7.5rem;
}

.user-mention {
  color: var(--dark-jungle-color);
  text-decoration: underline;
  font-weight: bold;
}

.user-suggestions-dropdown {
  max-height: 200px;
  overflow: scroll;
}

.user-mention:before {
  content: '@';
}

.text-uppercase {
  text-transform: uppercase;
}

.td-text-uppercase td, .td-text-uppercase td input {
  text-transform: uppercase;
}

.extra-letter-spacing {
  letter-spacing: 0.04em;
}

.min-w-385px {
  min-width: 385px;
}

.max-w-385px {
  max-width: 385px;
}

.min-w-800px {
  min-width: 800px
}

.max-w-1200px {
  max-width: 1200px;
}

.w-1200px {
  width: 1200px;
}

.h-3\.75 {
  height: 15px;
}

.h-4\.5 {
  height: 18px;
}

.w-4\.5 {
  width: 18px;
}

.min-w-4\.5 {
  min-width: 18px;
}

.min-h-42px {
  min-height: 42px;
}

.break-word {
  word-break: break-word;
}

/*BHTABLE*/
table.bh-table {
  border-collapse: collapse;
  border: 1px solid var(--pigeon-40-color);
  border-spacing: 0;
  border-radius: 50px;
}

table.bh-table thead th {
  padding: 0.75rem 0.375rem;
  text-align: center;
  background-color: var(--smoke-color);
  border: 1px solid var(--pigeon-40-color);
  word-wrap: break-word;
}

table.bh-table .border-b-2 {
  border-bottom: 2px solid var(--pigeon-color);
}

table.bh-table tr.bh-bg-pigeon-20 > th {
  background-color: var(--pigeon-20-color);
}

table.bh-table tr > th:nth-last-child(-n+2) {
  background-color: var(--smoke-color);
}

table.bh-table tr > th.bh-bg-smoke {
  background-color: var(--smoke-color);
}

table.bh-table td {
  padding: 0.25rem 0.375rem;
  width: max-content;
}

table.bh-table td > input {
  padding: 0rem;
}

table.bh-table-vertical th {
  background-color: var(--smoke-color);
  text-align: start;
}

table.bh-table-vertical th, table.bh-table-vertical td {
  padding: 0.75rem 0.375rem;
  border: 1px solid var(--pigeon-40-color);
}

table.bh-table-vertical {
  text-align: end;
}

tr.pt-3 > td {
  padding-top: 0.75rem;
}

table.bh-table td + td {
  border-left: 1px solid;
}

table.bh-table .bh-border-pigeon-60 > td {
  border-color: var(--pigeon-60-color);
}

table.bh-table .bh-border-pigeon-50 > td {
  border-color: var(--pigeon-50-color);
}

table.bh-table .bh-border-pigeon-40 > td {
  border-color: var(--pigeon-40-color);
}

table.bh-table .bh-border-pigeon-20 > td {
  border-color: var(--pigeon-20-color);
}

table.bh-table .bh-border-pigeon-20 .border > td {
  border: 1px solid var(--pigeon-20-color);
}

/*BHTABLE*/

.koosolek-table-red {
  color: red;
}

.koosolek-table-green {
  color: green;
}

.koosolek-table-blue {
  color: blue;
}

.koosolek-table-black {
  color: black;
}

.bh-tooltip {
  background-color: var(--deep-ocean-color) !important;
  color: var(--white-color) !important;
  padding: 4px 6px !important;
  line-height: 16px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif !important;
  opacity: 1 !important;
  z-index: 500;
  max-width: 300px;
  word-break: break-word;
}

.mt-1px {
  margin-top: 1px;
}

.p-1\.25 {
  padding: 5px;
}

.p-0\.75 {
  padding: 3px;
}

.pb-7\.5 {
  padding-bottom: 1.875rem;
}

@variants group-hover {
  .bh-bg-mint-50-group {
    background-color: var(--mint-50-color);
  }

  .bh-bg-none-group {
    background: none;
  }

  .bh-text-pigeon-group {
    color: var(--pigeon-color);
  }
}

.circle-progress-green {
  display: flex;
  border-radius: 50%;
  background: conic-gradient(var(--success-green-110-color) var(--progress), var(--pigeon-40-color) 0deg);
  animation: 200ms ease-out turn_in_green reverse;
}

.flex-share-modal {
  width: 792px;
}

@keyframes turn_in_green {
  5% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .95), var(--pigeon-40-color) 0deg);
  }
  10% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .9), var(--pigeon-40-color) 0deg);
  }
  15% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .85), var(--pigeon-40-color) 0deg);
  }
  20% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .8), var(--pigeon-40-color) 0deg);
  }
  25% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .75), var(--pigeon-40-color) 0deg);
  }
  30% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .7), var(--pigeon-40-color) 0deg);
  }
  35% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .65), var(--pigeon-40-color) 0deg);
  }
  40% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .6), var(--pigeon-40-color) 0deg);
  }
  45% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .55), var(--pigeon-40-color) 0deg);
  }
  50% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .5), var(--pigeon-40-color) 0deg);
  }
  55% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .45), var(--pigeon-40-color) 0deg);
  }
  60% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .4), var(--pigeon-40-color) 0deg);
  }
  65% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .35), var(--pigeon-40-color) 0deg);
  }
  70% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * 0.3), var(--pigeon-40-color) 0deg);
  }
  75% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * 0.25), var(--pigeon-40-color) 0deg);
  }
  80% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .2), var(--pigeon-40-color) 0deg);
  }
  85% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .15), var(--pigeon-40-color) 0deg);
  }
  90% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .1), var(--pigeon-40-color) 0deg);
  }
  95% {
    background: conic-gradient(var(--success-green-110-color) calc(var(--progress) * .05), var(--pigeon-40-color) 0deg);
  }
  100% {
    background: conic-gradient(var(--pigeon-40-color) 0deg);
  }
}

.circle-progress-blue {
  display: flex;
  border-radius: 50%;
  background: conic-gradient(var(--royal-blue-color) var(--progress), var(--pigeon-40-color) 0deg);
  animation: 200ms ease-out turn_in_green reverse;
}

@keyframes turn_in_blue {
  5% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .95), var(--pigeon-40-color) 0deg);
  }
  10% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .9), var(--pigeon-40-color) 0deg);
  }
  15% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .85), var(--pigeon-40-color) 0deg);
  }
  20% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .8), var(--pigeon-40-color) 0deg);
  }
  25% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .75), var(--pigeon-40-color) 0deg);
  }
  30% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .7), var(--pigeon-40-color) 0deg);
  }
  35% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .65), var(--pigeon-40-color) 0deg);
  }
  40% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .6), var(--pigeon-40-color) 0deg);
  }
  45% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .55), var(--pigeon-40-color) 0deg);
  }
  50% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .5), var(--pigeon-40-color) 0deg);
  }
  55% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .45), var(--pigeon-40-color) 0deg);
  }
  60% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .4), var(--pigeon-40-color) 0deg);
  }
  65% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .35), var(--pigeon-40-color) 0deg);
  }
  70% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * 0.3), var(--pigeon-40-color) 0deg);
  }
  75% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * 0.25), var(--pigeon-40-color) 0deg);
  }
  80% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .2), var(--pigeon-40-color) 0deg);
  }
  85% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .15), var(--pigeon-40-color) 0deg);
  }
  90% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .1), var(--pigeon-40-color) 0deg);
  }
  95% {
    background: conic-gradient(var(--royal-blue-color) calc(var(--progress) * .05), var(--pigeon-40-color) 0deg);
  }
  100% {
    background: conic-gradient(var(--pigeon-40-color) 0deg);
  }
}

.max-h-500px {
  max-height: 500px;
}

.w-25 {
  width: 100px;
}

.h-full-minus-280px {
  height: calc(100% - 280px);
}

.min-h-12 {
  min-height: 48px;
}

.text-nowrap {
  white-space: nowrap;
}

.max-w-half {
  max-width: 50%;
}

.react-datepicker {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
  border: 0;
  border-radius: 0.25rem;
  overflow: hidden;
}

.react-datepicker * {
  color: var(--deep-ocean-color) !important;
}

.react-datepicker__month-container {
  background-color: var(--raised-white-color) !important;
}

.react-datepicker__header {
  background-color: var(--raised-white-color) !important;
  border-bottom: 0 !important;
}

.react-datepicker__day--selected {
  background-color: var(--bauhub-green-color) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--white-color) !important;
  color: var(--deep-ocean-color) !important;
}

.react-datepicker__day-name {
  color: var(--deep-ocean-60-color) !important;
}

.react-datepicker__day--today {
  border: solid 1px var(--pigeon-60-color);
  font-weight: normal !important;
  border-radius: 0.25rem;
  box-sizing: content-box;
}

.react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
  background-color: var(--mint-120b-color) !important;
  color: var(--deep-ocean-color-origin) !important;
}

.react-datepicker__input-container input {
  width: 100%;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border-color: var(--pigeon-40-color) !important;
  border-radius: 4px;
  padding: 6px 16px 6px 4px;
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-time-picker__inputGroup__leadingZero {
  position: relative;
  z-index: 1;
  padding-left: 4px;
}

.react-time-picker--disabled {
  background-color: var(--smoke-color);
  border-radius: 4px !important;
}

.react-time-picker--disabled > .react-time-picker__wrapper {
  border-color: var(--smoke-color) !important;
}

.react-time-picker__inputGroup__input:invalid {
  background-color: var(--white-color) !important;
}

.react-time-picker__inputGroup__input:disabled {
  background-color: var(--smoke-color) !important;
}

.react-time-picker__inputGroup__input {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.form-table {
  width: 100%;
  height: 100%;
}

.form-table thead tr {
  font-size: 13px;
  line-height: 16px;
  vertical-align: middle;
  text-align: center;
  height: 24px;
}

.form-table thead tr th {
  border: 1px solid var(--pigeon-50-color);
  background-color: var(--smoke-color);
}

.form-table tbody tr td {
  border: 1px solid var(--pigeon-40-color);
}

.form-table-input {
  min-width: 40px !important;
}

.mnt-alused-table-row th {
  width: 5.882%;
}

.mnt-katend-table-row td {
  width: 6.66%;
}

.mnt-kraavid-table-row th {
  width: 7.142%;
}

.mnt-muld-table-row th {
  width: 6.25%;
}

.mnt-pinnas-table-row th {
  width: 8.33%;
}

.mnt-kandevoime-cell-wide {
  width: 10.52%;
}

.mnt-kandevoime-cell-short {
  width: 5.26%;
}

.directory-container {
  container-type: inline-size;
}

.bh-shadow-t-r-white {
  box-shadow: 1px -1px #FFFFFF;
  z-index: 10;
}

.sidebar-nav .fa-signature {
  width: 20px;
  margin: 2px;
}

.editor .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}

.editor .public-DraftEditorPlaceholder-hasFocus {
  display: none;
}

.editor .public-DraftEditorPlaceholder-inner {
  color: var(--deep-ocean-40-color);
}

.bottom-inset-shadow::before {
  content: "";
  position: absolute;
  height: 12px;
  left: 0;
  right: 0;
  bottom: 0;
  -moz-box-shadow: inset 0 -25px 20px -20px #F2F5F6;
  -webkit-box-shadow: inset 0 -25px 20px -20px #F2F5F6;
  box-shadow: inset 0 -25px 20px -20px #F2F5F6;
  pointer-events: all;
}

/* XEOKIT */

#bauhubSpinnerElement {
  z-index: 19;
  visibility: hidden;
}


.tree-view-container {
  padding-left: 12px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100% + 10px);
}

.tree-view-container ul {
  list-style: none;
  padding-left: 12px;
  pointer-events: none;
}

.tree-view-container ul li {
  position: relative;
  pointer-events: none;
  padding-top: 3px;
  padding-bottom: 3px;
  vertical-align: middle;
}

.tree-view-container ul li a {
  display: inline-block;
  height: 1px;
  left: -1.3em;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 1px;
  color: transparent;
  pointer-events: all;
}

.tree-view-container ul li a.plus, .tree-view-container ul li a.minus {
  pointer-events: all;
}

.tree-view-container ul li a.plus::after, .tree-view-container ul li a.minus::after {
  display: block;
  position: absolute;
  top: 0;
}

.tree-view-container ul li a.minus::after {
  width: 13px;
  height: 18px;
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMjMzLjQgNDA2LjZjMTIuNSAxMi41IDMyLjggMTIuNSA0NS4zIDBsMTkyLTE5MmMxMi41LTEyLjUgMTIuNS0zMi44IDAtNDUuM3MtMzIuOC0xMi41LTQ1LjMgMEwyNTYgMzM4LjcgODYuNiAxNjkuNGMtMTIuNS0xMi41LTMyLjgtMTIuNS00NS4zIDBzLTEyLjUgMzIuOCAwIDQ1LjNsMTkyIDE5MnoiLz48L3N2Zz4=")
}

.tree-view-container ul li a.plus::after {
  height: 18px;
  width: 8px;
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzEwLjYgMjMzLjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTE5MiAxOTJjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwcy0xMi41LTMyLjggMC00NS4zTDI0Mi43IDI1NiA3My40IDg2LjZjLTEyLjUtMTIuNS0xMi41LTMyLjggMC00NS4zczMyLjgtMTIuNSA0NS4zIDBsMTkyIDE5MnoiLz48L3N2Zz4=")
}

.tree-view-container ul li input {
  margin: 2px;
  vertical-align: top;
}

.tree-view-container ul li span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.tree-view-container ul li span {
  display: inline-block;
  width: calc(100% - 50px);
  padding-left: 2px;
  pointer-events: all;
  height: 22px;
}

.tree-view-container .highlighted-node { /* Appearance of node highlighted with TreeViewPlugin#showNode() */
  background-color: var(--mint-110-color);
}

/* ----------------------------------------------------------------------------------------------------------*/
/* ContextMenu */
/* ----------------------------------------------------------------------------------------------------------*/

.xeokit-context-menu {
  display: none;
  z-index: 300000;
  width: 200px;
  box-shadow: 0 3px 10px rgba(86, 116, 126, 0.3) !important;
  background-color: var(--raised-white-color) !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  border-radius: 4px !important;
  border-color: var(--pigeon-40-color) !important;
}

.xeokit-context-menu ul {
  list-style: none;
  margin-left: 0;
  padding: 0;
}

.xeokit-context-menu ul li {
  padding: 0.375rem 1rem;
  list-style-type: none;
  cursor: pointer;
  border-color: var(--pigeon-40-color) !important;
}

.xeokit-context-menu ul li:hover {
  background-color: var(--smoke-color);
}

.xeokit-context-menu ul li span {
  display: inline-block;
}

.xeokit-context-menu .disabled {
  color: gray;
  cursor: default;
}

.xeokit-context-menu .disabled:hover {
  color: gray;
  cursor: default;
}

#storeyMap {
  height: 280px;
  width: 280px;
  overflow-y: hidden;
  pointer-events: all;
  user-select: none;
}

#mapPointer {
  line-height: 1.8;
  text-align: center;
  position: absolute;
  width: 60px;
  height: 60px;
  cursor: none;
  transform: rotate(0deg);
  pointer-events: none;
  background-image: url(assets/images/storeyMapCamera.png);
  background-repeat: no-repeat;
  background-size: 60px 60px;
}

#bimNavCubeCanvas {
  position: absolute;
  width: 150px;
  height: 150px;
  bottom: 50px;
  right: 50px;
  z-index: 200000;
}

#myMarkerDiv {
  transform: translate(-71px, -100px);
}

.full-w-datepicker > div > div {
  max-width: 100%
}

#storeyMap img {
  user-drag: none;
  -webkit-user-drag: none;
}

/* ----------------------------------------------------------------------------------------------------------*/
/* File basket */
/* ----------------------------------------------------------------------------------------------------------*/

.animate-scale {
  animation: scale 400ms cubic-bezier(0, 0, 0.58, 1);
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.75);
  }
  100% {
    transform: scale(1);
  }
}

.recharts-legend-item-text {
  color: var(--deep-ocean-color) !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 7px;
}

.recharts-cartesian-axis-tick-value {
  color: var(--deep-ocean-color) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.48px !important;
  text-transform: uppercase !important;
}

.bim-file-converting-animation {
  animation-duration: 2s;
}

.full-w-datepicker > div > div > div {
  display: block;
}

.bottom-axis > .visx-axis-tick > svg > text {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 200% */
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.bottom-axis-line {
  stroke-width: 1;
  stroke: var(--pigeon-40-color)
}

.left-axis > .visx-axis-tick > svg > text {
  font-family: Lato !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 200% */
  text-transform: uppercase;
}

.chart-container > div > svg {
  overflow: visible !important
}

.inner-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viewer-ruler-label {
  padding: 0 4px !important;
}

.logo-loading-block-container {
  position: relative;
  width: 45.32px; /* Adjust to match SVG dimensions */
  height: 44.53px; /* Adjust to match SVG dimensions */
}

.logo-loading-block {
  position: absolute;
  background-color: #00B95F;
}

.logo-loading-static-block {
  width: 39.75px; /* Adjust to match SVG dimensions */
  height: 18.47px; /* Adjust to match SVG dimensions */
  bottom: 0;
  left: 5.57px; /* Adjust to match SVG positioning */
}

.logo-loading-animated-block {
  width: 30.65px; /* Adjust to match SVG dimensions */
  height: 18.47px; /* Adjust to match SVG dimensions */
  top: 2.04px; /* Adjust to match SVG positioning */
  left: 1.14px; /* Adjust to match SVG positioning */
  transform: rotate(-8.049deg);
  animation: logoLoadingMoveAndRotate 1400ms linear infinite;
}

@keyframes logoLoadingMoveAndRotate {
  0%, 100% {
    transform: translate(-1.41px, 0.4px) rotate(-8.049deg);
  }
  35.714286% {
    transform: translate(4.420171px, 5.553077px) rotate(0deg);
  }
  50% {
    transform: translate(4.420171px, 5.553077px) rotate(0deg);
  }
  85.714286% {
    transform: translate(-1.41px, 0.4px) rotate(-8.049deg);
  }
}

.form-base-field-quarter-width {
  max-width: calc(25% - 6px) !important;
}

.form-base-field-half-width {
  max-width: calc(50% - 4px) !important;
}

.form-people-field-width {
  max-width: calc(50% - 12px) !important;
}

.form-base-people-field-width {
  max-width: calc(50% - 20px) !important;
}

.form-base-pdf-info > div > p {
  margin: 0;
  font-size: 14px;
  line-height: 14px;
}

.rounded-r-3px {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rounded-l-3px {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.rounded-3px {
  border-radius: 3px;
}

.rounded-5px {
  border-radius: 5px;
}

.wysiwyg-output {
  width: 100%;

  table, table td {
    border: 1px solid;
  }
}

.wysiwyg-output ol {
  list-style-type: decimal;
  margin-left: 1.5em;
}

.wysiwyg-output ul {
  list-style-type: disc;
  margin-left: 1.5em;
}

/* Base styles for the div to simulate A4 paper */
.a4-paper {
  width: 210mm; /* A4 width */
  min-height: 297mm; /* A4 height */
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.custom-scale-90 {
  transform: scale(0.9);
  transform-origin: top center;
  width: calc(210mm / 0.9);
}

.a4-landscape {
  transform: scale(0.9);
  transform-origin: top center;
  width: calc(297mm / 0.9);
  min-height: 210mm;
}

.form-builder-weather-button > div {
  height: 100%;
}

.react-datepicker__triangle {
  display: none;
}