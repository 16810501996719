@font-face {
  font-family: "Lato";
  font-weight: 400;
  font-style: normal;
  src: url("webFonts/LatoRegular/LatoLatin-Regular.woff2") format("woff2"), url("webFonts/LatoRegular/LatoLatin-Regular.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-weight: 700;
  font-style: normal;
  src: url("webFonts/LatoBold/LatoLatin-Bold.woff2") format("woff2"), url("webFonts/LatoBold/LatoLatin-Bold.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-weight: 400;
  font-style: italic;
  src: url("webFonts/LatoItalic/LatoLatin-Italic.woff2") format("woff2"), url("webFonts/LatoItalic/LatoLatin-Italic.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-weight: 700;
  font-style: italic;
  src: url("webFonts/LatoBoldItalic/LatoLatin-BoldItalic.woff2") format("woff2"), url("webFonts/LatoBoldItalic/LatoLatin-BoldItalic.woff") format("woff");
}